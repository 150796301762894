import React, {useState} from 'react';
import cn from "classnames";
import getTitleId from "../helpers/getTitleId";
import { LocalizedLink } from '../commonComponents';

const SidebarSummary = ({ inThisArticle }) => {
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      {(inThisArticle) && (
        <>
          <div className="borderbox-tw tw-bg-accruent_kingfisher_daisy tw-h-[4px] tw-w-full tw-mt-[34px] tw-mb-10"></div>
          <div className="righ-min-scroll">
            <div className="collapse-wrapper">
              <div id="accordion-right">

                <div className="tw-bg-transparent tw-border-0 tw-rounded-s tw-text-heading-color tw-max-w-full tw-relative">
                  <div onClick={() => setCollapse(!collapse)} className="tw-p-0 tw-pl-0 tw-bg-transparent tw-border-0 tw-pr-2" id="heading_2">
                    <a
                      className="collapsed click-for-scroll tw-uppercase tw-text-accruent-heading-color tw-cursor-pointer tw-flex tw-font-Poppins tw-text-lgFont tw-justify-between tw-w-full tw-base_transition"
                      data-toggle="collapse"
                      data-target="#left_2"
                      aria-expanded="false"
                      aria-controls="left_2"
                    >
                      <span className="is-uppercase">{inThisArticle.title}</span>
                      <span className="arrow">
                            <span className="tw-inline-block">
                              <i className="fas fa-angle-down"></i>
                            </span>
                            <span className="up tw-hidden">
                              <i className="fas fa-angle-up"></i>
                            </span>
                          </span>
                    </a>
                  </div>
                  <div
                    id="left_2"
                    className={cn("collapse", {"tw-h-0 tw-overflow-hidden": collapse})}
                    aria-labelledby="heading_2"
                    data-parent="#accordion-right"
                  >
                    <div>
                      {(inThisArticle?.relationships?.fieldArticleContent?.length > 0) &&
                        inThisArticle.relationships.fieldArticleContent.map((item) => (
                          <div className="column is-12 pl-0">
                            {(!item?.fieldHideTitle) &&<div className="field-article-section tw-mt-2">
                              {item?.title}
                            </div>}
                            {(item?.relationships?.fieldArticleSectionLinks?.length > 0) &&
                              item.relationships.fieldArticleSectionLinks.map((sectionLink) => (
                                <div className="tw-font-Roboto tw-mt-[8px] tw-ml-[12px] tw-text-[16px]">
                                  <LocalizedLink
                                    to={`#${getTitleId(sectionLink.title)}`}
                                    className="resource-overview-link tw-base_transition tw-cursor-pointer tw-text-tab-hover-color tw-text-mdFont tw-font-Roboto"
                                  >
                                    {sectionLink.title}
                                  </LocalizedLink>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SidebarSummary;
